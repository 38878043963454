<script>
  import { createEventDispatcher } from 'svelte'
  import { fly } from 'svelte/transition'

  import texts from '../texts'
  import { fontWeights, fontSizes } from '../constants'
  import { fontWeight, fontSize, fontAltChars, textSlideIndex, darkMode, swipe } from '../stores'
  import Text from './Text.svelte'
  import { quadIn } from 'svelte/easing'

  const dispatch = createEventDispatcher()
  let innerWidth = window.innerWidth
  let innerHeight = window.innerHeight
  let inState = { x: window.innerWidth, y: 0, opacity: 1 }
  let outState = { x: -window.innerWidth, y: 0, opacity: 1 }

  $: weight = fontWeights[0] + $fontWeight * (fontWeights[1] - fontWeights[0])
  $: size = fontSizes[0] + quadIn($fontSize) * (fontSizes[1] - fontSizes[0])
  $: background = $darkMode ? 'var(--black)' : 'var(--white)'
  $: color = $darkMode ? 'var(--white)' : 'var(--black)'
  $: maxFontSize =
    innerWidth <= 768
      ? 0.86 * innerWidth < 0.44 * innerHeight
        ? `86vw`
        : `calc(var(--vh, 1vh) * 44)`
      : 0.53 * innerWidth < 0.689 * innerHeight
      ? `53vw`
      : `calc(var(--vh, 1vh) * 68.9)`

  $: flyIn = {
    ...inState,
    duration: $swipe.duration.in,
  }
  $: flyOut = {
    ...outState,
    duration: $swipe.duration.out,
  }

  const onResize = () => {
    inState = { x: window.innerWidth, y: 0, opacity: 1 }
    outState = { x: -window.innerWidth, y: 0, opacity: 1 }
  }
</script>

<style>
  .text-background {
    position: absolute;
    top: 0;
    left: -2px;
    right: -2px;
    bottom: 0;
    z-index: -100;
    transform: translateZ(-1px);
  }

  .text-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 100;
    transform: translateZ(1px);
  }

  .text {
    position: absolute;
    z-index: 100;
    display: flex;
    align-items: center;
    transform: translateZ(1px);

    top: calc(var(--vh, 1vh) * 16.6);
    bottom: 8vw;
    z-index: 1;
    padding-bottom: calc(var(--vh, 1vh) * 6.5);

    left: 4.4vw;
    right: 4.4vw;
  }

  @media (max-width: 768px) {
    .text {
      bottom: 25vw;
    }
  }</style>

<svelte:window on:resize={onResize} bind:innerWidth bind:innerHeight />

{#each texts as slide, i}
  {#if i === $textSlideIndex}
    <div
      class="text-container"
      in:fly={flyIn}
      out:fly={flyOut}
      on:outrostart={() => dispatch('slidestart')}
      on:outroend={() => dispatch('slideend')}>
      <div class="text" style="font-size: {maxFontSize}">
        <Text {size} {weight} {color} alt={$fontAltChars} text={slide.Text} on:timeout />
      </div>
    </div>
  {/if}
{/each}

{#each texts as slide, i}
  {#if i === $textSlideIndex}
    <div
      class="text-background"
      in:fly={flyIn}
      out:fly={flyOut}
      style="background: {background};" />
  {/if}
{/each}
