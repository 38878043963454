<script>
  import { onDestroy, afterUpdate } from 'svelte'
  import { cubicIn, quintIn } from 'svelte/easing'
  import { editing, edited } from '../stores'

  import AltCharacterToggler from '../utils/AltCharacterToggler'
  import AudioPlayer from '../utils/AudioPlayer'

  function clamp(value, min, max) {
    return Math.min(Math.max(value, min), max)
  }

  function map_range(value, low1, high1, low2, high2) {
    return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1)
  }

  // some component properties
  export let size, weight, color, text, alt
  let textarea, passive, html
  let showEditor = false

  let toggler = new AltCharacterToggler(text)

  let altText = text
  let prevNumAlt = toggler.originalNumAlt
  $: numAlt = Math.min(Math.round(alt * toggler.length, toggler.length))
  $: empty = passive && passive.textContent.length === 0
  $: letterSpacing = clamp(cubicIn(map_range(size, 4.4, 5, 1, 0)) * 0.01, 0, 0.01)
  $: lineHeight = (1 + quintIn(map_range(size, 5, 100, 1, 0)) * 0.3).toFixed(1)
  $: style = `font-size: ${size}%; font-weight: ${weight}; color: ${color}; letter-spacing: ${letterSpacing}em; line-height: ${lineHeight}`

  $: {
    if (numAlt === toggler.originalNumAlt && passive && passive.textContent === toggler.plainText) {
      // always replace the same character when the value is reset
      toggler.init()
      altText = toggler.text
    } else if (prevNumAlt !== numAlt) {
      toggler.setAmount(numAlt)
      altText = toggler.text
    }
    prevNumAlt = numAlt
  }

  const config = {
    attributes: true,
    childList: true,
    subtree: true,
    characterData: true,
    characterDataOldValue: true,
    attributeFilter: ['style'],
  }

  // Callback function to execute when mutations are observed
  const callback = function (mutationsList, observer) {
    observer.disconnect()
    observer.takeRecords()

    for (const mutation of mutationsList) {
      if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
        // remove spans that are somehow added by the browser
        const spans = Array.from(mutation.addedNodes).filter(
          (node) => node.parentNode && (node.tagName === 'SPAN' || node.tagName === 'DIV'),
        )
        spans.forEach((span) => (span.outerHTML = span.innerHTML))
      } else if (mutation.type === 'attributes' && mutation.target !== textarea) {
        mutation.target.removeAttribute('style')
      }
    }

    observer.observe(textarea, config)
  }

  let observer

  afterUpdate(() => {
    if (textarea) {
      const observer = new MutationObserver(callback)
      observer.observe(textarea, config)
      textarea.focus()
    } else if (observer) {
      observer.disconnect()
    }
  })

  onDestroy(() => {
    if (observer) observer.disconnect()
  })

  const onBlur = () => {
    const cleaned = html
      .replace(/&#8203;/gi, '')
      .replace(/<p><\/p>/gi, '')
      .replace(/<p><br><\/p>/gi, '')

    toggler = new AltCharacterToggler(cleaned)
    toggler.setPercentage(alt)
    altText = toggler.text

    $edited = cleaned.length
    showEditor = false
  }

  const onPaste = (event) => {
    event.preventDefault()
    document.execCommand('inserttext', false, event.clipboardData.getData('text/plain'))
  }

  const onClickTextarea = () => {
    showEditor = true
    html = '<p>&#8203;</p>' // zero-width white space, otherwise caret is not working
    altText = html

    AudioPlayer.fadeOut()
    AudioPlayer.isPlaying = false
    AudioPlayer.cancel()

    $editing = true
    $edited = 0
  }

  const onDone = () => {
    $editing = false
  }
</script>

<style>
  div {
    text-align: center;
    margin: 0 auto;
    font-size: 10%;
    font-variant-ligatures: normal;
    display: inline-block;
    caret-color: transparent;
    padding-left: 2px;
  }

  div.editable {
    caret-color: var(--text-color);
    min-width: 100px;
  }

  div:focus {
    border: 0;
    outline: none;
  }

  div :global(i) {
    font-style: normal;
    font-feature-settings: 'salt';
  }

  div :global(span.case) {
    font-style: normal;
    font-feature-settings: 'case';
  }

  div :global(p) {
    margin: 0;
  }

  div :global(p + p) {
    margin-top: 0.7em;
  }

  /* when there's not text in contenteditable we need to make the click area larger */
  :global(div.empty) {
    width: 100%;
    height: 100%;
  }

  :global(div.empty p) {
    width: 100%;
    height: 100%;
  }

  :global(#done-btn) {
    position: absolute;
    bottom: 0vw;
    left: 50%;
    transform: translateX(-50%);
    color: var(--blue);
    font-size: var(--label-size);
    border: 0;
    background: 0;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    :global(#done-btn) {
      bottom: 4vw;
    }
  }</style>

{#if showEditor}
  <div
    bind:this={textarea}
    class="editable"
    {style}
    contenteditable="true"
    spellcheck="false"
    on:blur={onBlur}
    on:paste={onPaste}
    bind:innerHTML={html} />
{:else}
  <div class:empty bind:this={passive} {style} on:click={onClickTextarea}>
    {@html altText}
  </div>
{/if}

{#if $editing}<button type="button" id="done-btn" on:click={onDone}> Done editing </button>{/if}
