<script>
  import ClickOutside from 'svelte-click-outside'
  import { fontWeight } from '../stores'
  import Button from './ui/Button.svelte'
  import { fontWeights } from '../constants'

  let visible = false

  const weights = {
    Regular: 80,
    Medium: 100,
    Bold: 125,
    Heavy: 150,
  }

  const onClickOutside = () => (visible = false)
  const setWeight = (weight) => {
    const normalized = (weights[weight] - fontWeights[0]) / (fontWeights[1] - fontWeights[0])
    fontWeight.set(normalized)
    visible = false
  }
</script>

<style>
  :global(.weight-dropdown) {
    position: relative;
    padding-right: calc(var(--button-size) * 1.8) !important;
  }

  :global(.weight-dropdown:after) {
    content: '';
    position: absolute;
    width: 0.6em;
    height: 0.5em;
    top: 0.7em;
    right: 0.7em;
    background-image: url('/images/triangle.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }

  .menu {
    position: absolute;
    top: calc(var(--button-size) * 2);
    left: 0.2vw;
    box-sizing: border-box;
    margin: 0;
    z-index: 1;
  }

  li {
    font-size: var(--button-size);
    padding: 0.2em 0 0.3em 0.75em;
    width: 6.5em;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    background: var(--green);
    color: var(--black);
  }

  li:nth-child(2n) {
    background: #4ed707;
  }</style>

<ClickOutside on:clickoutside={onClickOutside}>
  <Button label="Gestures" className="weight-dropdown" on:click={() => (visible = !visible)} />

  {#if visible}
    <ul class="menu">
      {#each Object.keys(weights) as weight, i}
        <li class={weight.toLowerCase()} on:click={() => setWeight(weight)}>{weight}</li>
      {/each}
    </ul>
  {/if}
</ClickOutside>
