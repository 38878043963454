<script>
  import { getContext } from 'svelte'

  export let transform = { x: 0, y: 0, rotation: 0 }

  const gui = getContext('gui')

  let style
  $: {
    style = `
      transform-origin: 0 0;
      transform: translate3d(${transform.x}vw, calc(var(--vh, 1vh) *  ${
      transform.y
    }), 1px) rotateZ(${transform.rotation || 0}deg);
    `
  }
</script>

<style>
  .gesture {
    position: absolute;
    line-height: 0;
    top: 0;
    pointer-events: none;
  }

  .gui:before {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: red;
    position: absolute;
    z-index: 9999;
    transform: translate3d(-10px, -10px, 0);
  }</style>

<div class="gesture" class:gui={$gui} {style}>
  <slot />
</div>
