<script>
  export let value
</script>

<style>
  .selectors {
    font-size: var(--label-size);
    margin-left: 3.5vw;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  input {
    display: none;
  }

  label {
    display: inline;
    position: relative;
    cursor: pointer;
  }

  input:checked + label:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.25em;
    height: 0.1em;
    background-image: url('/images/underline-black.svg');
    background-repeat: repeat-x;
  }

  :global(html.dark input:checked + label:after) {
    background-image: url('/images/underline-white.svg') !important;
  }

  span + span {
    margin-left: 1.5em;
  }

  @media (max-width: 768px) {
    .selectors {
      margin-left: 0;
    }

    span + span {
      margin-left: 0.7em;
    }

    label span {
      display: none;
    }
  }</style>

<div class="selectors">
  <span>
    <input id="size" type="radio" bind:group={value} value="fontSize" />
    <label id="size-btn" for="size"> Size</label>
  </span>

  <span>
    <input id="weight" type="radio" bind:group={value} value="fontWeight" />
    <label id="weight-btn" for="weight"> Weight </label>
  </span>

  <span>
    <input id="alt" type="radio" bind:group={value} value="fontAltChars" />
    <label id="alt-btn" for="alt">Alt characters<span>:</span></label>
  </span>
</div>
